export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/Users/Wem/MH/mh-landing/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/articles',
    /* internal name: '/articles' */
    /* no component */
    children: [
      {
        path: '',
        name: '/articles/',
        component: () => import('/Users/Wem/MH/mh-landing/src/pages/articles/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/articles/[id]',
        component: () => import('/Users/Wem/MH/mh-landing/src/pages/articles/[id].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/contact',
    name: '/contact',
    component: () => import('/Users/Wem/MH/mh-landing/src/pages/contact.vue'),
    /* no children */
  },
  {
    path: '/events',
    /* internal name: '/events' */
    /* no component */
    children: [
      {
        path: ':id',
        name: '/events/[id]',
        component: () => import('/Users/Wem/MH/mh-landing/src/pages/events/[id].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/gdpr',
    name: '/gdpr',
    component: () => import('/Users/Wem/MH/mh-landing/src/pages/gdpr.vue'),
    /* no children */
  },
  {
    path: '/newarticle',
    /* internal name: '/newarticle' */
    /* no component */
    children: [
      {
        path: '',
        name: '/newarticle/',
        component: () => import('/Users/Wem/MH/mh-landing/src/pages/newarticle/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/newarticle/[id]',
        component: () => import('/Users/Wem/MH/mh-landing/src/pages/newarticle/[id].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/password',
    name: '/password',
    component: () => import('/Users/Wem/MH/mh-landing/src/pages/password.vue'),
    /* no children */
  },
  {
    path: '/register',
    name: '/register',
    component: () => import('/Users/Wem/MH/mh-landing/src/pages/register.vue'),
    /* no children */
  },
  {
    path: '/users',
    /* internal name: '/users' */
    /* no component */
    children: [
      {
        path: 'userList',
        name: '/users/userList',
        component: () => import('/Users/Wem/MH/mh-landing/src/pages/users/userList.vue'),
        /* no children */
      },
      {
        path: 'userProfile',
        name: '/users/userProfile',
        component: () => import('/Users/Wem/MH/mh-landing/src/pages/users/userProfile.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/verify-email',
    name: '/verify-email',
    component: () => import('/Users/Wem/MH/mh-landing/src/pages/verify-email.vue'),
    /* no children */
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

